@import "../../../../Assets/stylesheets/bootstrap/variables";

.scope-themed-event-category {
    $theme-color-halloween: get-color('red', '900');
    $theme-color-newyearseve: #AB8D3F;

    .hotline {
        font-size: $font-size-big;
        font-weight: $font-weight-medium;

        &.hotline-theme-halloween {
            color: $white;
            background: $theme-color-halloween;
        }

        &.hotline-theme-silvester {
            color: $white;
            background: $theme-color-newyearseve;
        }
    }
}
